import * as React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import BandcampPlayer from '@components/BandcampPlayer'
import Layout from '@components/Layout'
import { Icons } from '@components/Social'
import UI, { Flex, Box, Text, H1, H2, H3 } from '@components/UI'
import { mobileDisplayToggle } from '@utils'

const Section = styled(Box).attrs({
  pb: 5
})``

const H4 = styled(UI.H4).attrs({
  color: 'dark'
})``

const StreamIcons = ({
  mobile,
  links
}: {
  mobile?: boolean
  links: Record<string, string>
}) => (
  <Icons
    links={links}
    pb={[1, 1, 5]}
    pl={[2, 2, 0]}
    pt={[2, 2, 0]}
    width="100%"
    iconMaxWidth="53px"
    maxWidth={['420px', '354px']}
    mx={['auto', 0, 0]}
    display={mobileDisplayToggle(mobile, 'flex')}
  />
)

const InfoWrap = styled(Flex).attrs({
  pl: [0, 0, 4],
  pt: [3, 3, 0],
  pr: 2,
  flexDirection: 'column'
})``

const PlayerWrap = styled(Flex).attrs({
  mx: ['auto', 'auto', '0px'],
  maxWidth: ['420px', '360px', '360px'],
  minWidth: '360px',
  flexDirection: 'column'
})``

const Container = styled(Flex).attrs({
  flexDirection: ['column', 'column', 'row']
})``

const AlbumTitle = ({
  title,
  artist,
  mobile
}: {
  title: string
  artist: string
  mobile?: boolean
}) => {
  const AlbumTitleBox = styled(Box).attrs({
    display: mobileDisplayToggle(mobile, 'initial')
  })``
  return (
    <AlbumTitleBox pb={[3, 4, 4]}>
      <H2 pb={1}>{title}</H2>
      <H4>{`by ${artist}`}</H4>
    </AlbumTitleBox>
  )
}

// STODO read about Record type, make sure I'm using it correctly
interface ReleaseProps {
  pageContext: {
    title: string
    artist: string
    description: string
    lyrics: string
    credits: string
    bandcampPlayer: {
      url: string
      height: number
    }
    links: Record<string, string>
  }
}

const Release = ({
  pageContext: {
    title,
    artist,
    description,
    lyrics,
    credits,
    links,
    bandcampPlayer
  }
}: ReleaseProps) => {
  const artistTitle = `${artist} - ${title}`
  const svgLinks = {}
  _.each(links, (link, key) => (svgLinks[`svg_${key}`] = link))
  return (
    <Layout goBackTo="music" title={title}>
      <Container>
        <AlbumTitle mobile artist={artist} title={title} />
        <PlayerWrap>
          <StreamIcons mobile links={svgLinks} />
          <BandcampPlayer
            height={bandcampPlayer.height}
            url={bandcampPlayer.url}
            title={artistTitle}
          />
        </PlayerWrap>
        <InfoWrap>
          <AlbumTitle artist={artist} title={title} />
          <StreamIcons links={svgLinks} />
          {description ? (
            <Section>
              <H3 pb={1}>Description</H3>
              <Text color="dark">{description}</Text>
            </Section>
          ) : null}
          {lyrics ? (
            <Section>
              <H3 pb={1}>Lyrics</H3>
              <Text color="dark">{lyrics}</Text>
            </Section>
          ) : null}
          <Section>
            <H3 pb={1}>Credits</H3>
            <Text color="dark">{credits}</Text>
          </Section>
        </InfoWrap>
      </Container>
    </Layout>
  )
}

export default Release
