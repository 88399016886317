import * as React from 'react'
import _ from 'lodash'
import styled from 'styled-components'

import { Box } from '@components/UI'

// STODO
import spinner from '@images/ui/loading_spinner_01.gif'

// STODO use same IFrame everywhere, search global for IFrame and iframe
const IFrame = styled.iframe`
  background-image: url("${spinner}");   
  background-repeat: no-repeat;
  background-position: 50% 100px;
  border: 0;
`

type BandcampPlayerProps = { url: string; title: string; height: number }
class BandcampPlayer extends React.Component<
  BandcampPlayerProps,
  { width: number }
> {
  container = React.createRef<HTMLElement>()

  state = { width: 0 }

  updateDimensions = _.throttle(
    () => {
      const { width } = this.state
      const newWidth = _.get(this.container, 'current.offsetWidth', width)

      if (newWidth !== width) {
        this.setState({ width: newWidth })
      }
    },
    200,
    { leading: false, trailing: true }
  )

  componentDidMount() {
    this.setState({ width: this.container.current.offsetWidth })
    window.addEventListener('resize', this.updateDimensions)
  }

  render() {
    const { title, url, height } = this.props
    const { width } = this.state

    const calcHeight = height - 354 + width

    return (
      <Box ref={this.container} minWidth={['300px', '354px']}>
        <IFrame
          title={title}
          width="100%"
          height={`${calcHeight}px`}
          src={url}
          seamless
        />
      </Box>
    )
  }
}

export default BandcampPlayer
